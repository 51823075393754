<template>
  <AuthLayout :img-url="imgUrl">
    <b-card-title title-tag="h2" class="font-weight-bold mb-1"> Riskomer'e Hoşgeldiniz! 👋 </b-card-title>
    <b-card-text class="mb-2"> E-posta ve şifreniz ile güvenli giriş yapabilirsiniz. </b-card-text>

    <!-- form -->
    <validation-observer ref="loginValidation">
      <b-form class="auth-login-form mt-2" @submit.prevent="login">
        <app-input v-model="email" type="email" name="E-Posta" label="E-Posta:" placeholder="E-Posta" rules="required|email" />
        <app-input v-model="password" type="password" name="Şifre" label="Şifre:" placeholder="Şifreniz..." rules="required" />
        <b-button variant="primary" type="submit" block> Giriş Yap </b-button>
      </b-form>
    </validation-observer>
    <b-card-text class="text-center mt-2">
      <b-link :to="{ name: 'forgot-password' }">
        <span>Şifrenizi mi unuttunuz?</span>
      </b-link>
    </b-card-text>
  </AuthLayout>
</template>

<script>
/* eslint-disable global-require */
import { ValidationObserver } from "vee-validate";
import AuthLayout from "./AuthLayout.vue";

export default {
  components: {
    AuthLayout,
    ValidationObserver,
  },
  data() {
    return {
      password: null,
      email: null,
    };
  },
  computed: {
    imgUrl() {
      const { skin } = this.$store.state.appConfig.layout;
      return skin === "dark" ? require("@/assets/images/pages/login-v2-dark.svg") : require("@/assets/images/pages/login-v2.svg");
    },
  },
  methods: {
    login() {
      this.$validate(this.$refs.loginValidation, () => {
        this.$store.dispatch("login", {
          email: this.email,
          password: this.password,
        });
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
